<template>
    <modal class="supplementModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">补账</div>
        <div class="modal-body">
            <div class="title">账户信息</div>
            <div class="li-box">
                <div class="li-flex">
                    <span class="lable-text">账单日期：</span>
                    <div class="from-input">
                        <el-date-picker class="from-date"
                            :clearable="false"
                            :editable="false"
                            v-model="rptDate"
                            type="date"
                            placeholder=""
                            :default-value="new Date()">
                        </el-date-picker>
                    </div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">挂账金额：</span>
                    <div class="from-input"><input-pattern v-model="corpMoney" pattern="number" :min="0" :dplaces="2"/></div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">原始账单号：</span>
                    <div class="from-input"><input type="text" v-model="voucherNo" /></div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">担保人：</span>
                    <div class="from-input"><input type="text" v-model="guarantor" /></div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">备注：</span>
                    <div class="from-input"><input type="text" v-model="remark" maxlength="25"/></div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()"> 确认</button>
        </div>
    </modal>
</template>

<script>

// 补账
export default {
    name:'transInfoModel',
    emits:["close","confirm"],
    props:{
        isShow:Boolean,
        data:{
            typeof:Object,
            default:null
        }
    },
    data(){
        return{
            /**账单日期 */
            rptDate:new Date(),
            //挂账金额
            corpMoney:"",
            //原始单号
            voucherNo:"",
            //担保人
            guarantor:"",
            //备注
            remark:''
        }
    },
    mounted(){
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.rptDate=new Date();
                this.corpMoney="";
                this.voucherNo="";
                this.guarantor="";
                this.remark="";
            }
        }
    },
    methods:{
        hide(){
            this.$emit("close");
        },
        /**点击确认 */
        confirm(){
            if(!(this.corpMoney>0)){
                this.$message.warning("挂账金额必须大于0!");
                return;
            }
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let param={
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Corp_AutoID:this.data?.Corp_AutoID,//原账户ID
                Rpt_Date:new Date(this.rptDate).Format("yyyy-MM-dd"),//业务日期
                Corp_Money:this.corpMoney,//挂账金额
                VoucherNo:this.voucherNo,//原始单号
                Guarantor:this.guarantor,//担保人
                Remark:this.remark,
                LastUpdated_Time:this.data?.LastUpdated_Time,//原账户最后更新时间
            }
            console.log(param)
            this.$httpAES.post("Bestech.CloudPos.SupplementARCorpInfo",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("补账成功");
                    console.log(d)
                    this.$emit("confirm",d.ResponseBody?.ARCorpConsume,d.ResponseBody?.LastUpdated_Time);
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('补账失败：'+e.message);
                console.log('补账失败：',e);
            })
        }
    }
}
</script>

<style lang="scss">
@import './supplementModel.scss'
</style>